import { useEffect, useState } from "react";
import Input from "../../components/Input";
import { useDispatch } from "react-redux";
import {
  changeFilterAction,
  changePaginationAction,
  changeSearchAction,
  loadProjectsSpecific,
  resetFilterAction,
} from "../../store/projects/actions";
import {
  useProjectsSelector,
  useFilterProjectSelector,
  useFilterValuesSelector,
  usePaginationSelector,
  useSearchSelector,
  useSelectedProjectSelector,
  useViewModeTabsSelector,
  useIndicatorsSelector,
  useAllProjectsSelector,
} from "../../store/projects/selectors";
import { ProjectStatusCard } from "./components/ProjectStatusCard";
import { AiOutlineHistory } from "react-icons/ai";
import { GoogleMap } from "../../components/Map/GoogleMap";
import { MultiSelect } from "../../components/MultiSelect";
import { SelectOption } from "../../components/MultiSelect/SelectOption";
import { ProjectTabs } from "./components/ProjectTabs";
import { IoFilter } from "react-icons/io5";
import { IoMdArrowDropdown, IoMdDownload, IoMdSettings } from "react-icons/io";
import { GrPowerReset } from "react-icons/gr";
import { ViewModeTabs } from "./components/ViewModeTabs";
import Pagination from "../../components/Pagination";
import { TableProjects } from "./components/TableProjects";
import { CurrentProjectGrid } from "./components/CurrentProjectGrid";
import { useNavigate, useSearchParams } from "react-router-dom";
import { EViewMode } from "../../types";
import { FilterDrawer } from "./components/FilterDrawer";
import { fetchFilters, fetchMap } from "../../api/home.api";
import { useUserSelector } from "../../store/user/selectors";

function HomePage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const search = useSearchSelector();
  const { total, perPage } = usePaginationSelector();
  const indicators = useIndicatorsSelector();
  const selected = useSelectedProjectSelector();
  const viewMode = useViewModeTabsSelector();
  const { device_type: deviceTypes } = useFilterProjectSelector();
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const { role } = useUserSelector();
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get("page");

  const handleDrawerOpen = () => {
    setIsDrawerOpen(true); // Function to open the drawer
  };
  const handleDrawerClose = () => {
    setIsDrawerOpen(false); // Function to close the drawer
  };

  const {
    data: { device_types },
  } = useFilterValuesSelector();

  const {
    data: { items: currentProjects },
    isLoading,
  } = useProjectsSelector();

  const {
    data: { items: allProjects },
  } = useAllProjectsSelector();

  const handleFilterChange = (key: string, value: unknown) => {
    dispatch(changeFilterAction({ [key]: value }));
    searchParams.delete("page");
  };

  const handleChangePage = (page: number) => {
    setSearchParams({ page: page.toString() });
  };

  useEffect(() => {
    dispatch(changePaginationAction({ page: Number(page ?? 1) }));
  }, [page]);

  useEffect(() => {
    dispatch(loadProjectsSpecific.submit());
  }, [selected, page, perPage, search]);

  useEffect(() => {
    dispatch(resetFilterAction());
    fetchFilters(role);
    fetchMap(role);
  }, []);

  const handleNavigate = () => {
    navigate("/add-new-project");
  };

  return (
    <div className="relative m-6 flex flex-col">
      <div className="mb-4 flex h-full w-1/4 flex-col items-start justify-start gap-4 lg:flex-row">
        <Input
          variant="outlined"
          type="search"
          id="default-search"
          className="color block w-full gap-4 rounded-md border border-black p-2 text-sm text-black"
          label={"Search project"}
          value={search}
          onChange={(e) => dispatch(changeSearchAction(e.target.value))}
        />
        <div className="absolute right-6 flex items-center gap-6">
          <MultiSelect
            className="h-full w-48"
            label="Project Type"
            color="gray"
            variant="outlined"
            value={deviceTypes}
            onChange={(value: any) => {
              const copy: any = new Set(deviceTypes);

              const isExist = copy.has(value);

              if (isExist) {
                copy.delete(value);
                handleFilterChange("device_types", [...copy]);
              } else {
                copy.add(value);
                handleFilterChange("device_types", [...copy]);
              }
            }}
          >
            {device_types?.slice(0, 128).map((item, index) => (
              <SelectOption value={item} key={`${index}-${item}`}>
                {item}
              </SelectOption>
            )) ?? <></>}
          </MultiSelect>
          <button
            className="w-42 h-10 gap-2.5 rounded-md bg-blue-500 px-6 py-2 text-sm font-medium text-white"
            onClick={handleNavigate}
          >
            Add new project
          </button>
        </div>
      </div>

      <div className="mb-4 grid w-full grid-cols-4 gap-6">
        <div className="rounded-lg bg-white px-1 shadow-md shadow-black/5">
          <ProjectStatusCard
            statusName={"All Projects"}
            value={indicators.total}
            icon={<AiOutlineHistory />}
            iconBackground="bg-[#482F8B]/20"
          />
        </div>
        <div className="rounded-lg bg-white px-1 shadow-md shadow-black/5">
          <ProjectStatusCard
            statusName={"In Progress"}
            value={indicators.inProgress}
            icon={<AiOutlineHistory />}
            iconBackground="bg-[#FF6C37]/20"
          />
        </div>
      </div>

      <div className="mb-4 h-[300px] w-full">
        <div className="h-full w-full overflow-hidden rounded-lg bg-white p-3 shadow-md">
          <GoogleMap
            mapContainerStyle={{
              width: "100%",
              height: "100%",
              borderRadius: "5px",
            }}
            currentProjects={allProjects}
          />
        </div>
      </div>

      <FilterDrawer isOpen={isDrawerOpen} onClose={handleDrawerClose} />

      <div className="mb-4 flex justify-between">
        <ProjectTabs />

        <div className="flex flex-row items-center justify-center gap-3">
          <button
            className="rounded-md border border-gray-300 p-3 shadow-sm hover:border-[#231F20]/10 hover:bg-primaryPurple-100/20"
            onClick={handleDrawerOpen}
          >
            <IoFilter className="text-[#714ECE]" />
          </button>

          <button className="has-tooltip rounded-md border border-gray-300 bg-blue-gray-100/30 p-3 shadow-sm hover:border-[#231F20]/10">
            <span className="tooltip text-nowrap left-0 mt-8 rounded bg-gray-100 p-1 text-sm text-primaryPurple-100 shadow-sm">
              Coming soon...
            </span>
            <IoMdSettings className="text-[#714ECE]" />
          </button>

          <button className="has-tooltip rounded-md border border-gray-300 bg-blue-gray-100/30 p-3 shadow-sm hover:border-[#231F20]/10">
            <span className="tooltip text-nowrap left-0 mt-8 rounded bg-gray-100 p-1 text-sm text-primaryPurple-100 shadow-sm">
              Coming soon...
            </span>
            <GrPowerReset className="text-[#714ECE]" />
          </button>

          <button className="has-tooltip rounded-md border border-gray-300 bg-blue-gray-100/30 p-3 shadow-sm hover:border-[#231F20]/10">
            <span className="tooltip text-nowrap left-0 mt-8 rounded bg-gray-100 p-1 text-sm text-primaryPurple-100 shadow-sm">
              Coming soon...
            </span>
            <span className="flex items-center gap-2">
              <IoMdDownload className="text-[#714ECE]" />
              <IoMdArrowDropdown className="text-[#714ECE]" />
            </span>
          </button>

          <ViewModeTabs />
        </div>
      </div>

      {viewMode === EViewMode.LIST ? (
        <div>
          <TableProjects reProjects={currentProjects} isLoading={isLoading} />
        </div>
      ) : (
        <div className="flex w-full justify-between gap-4">
          <div className="xxl:grid-cols-3 grid h-fit w-full grid-cols-1 gap-6 sm:grid-cols-1 xl:grid-cols-1 2xl:grid-cols-2 3xl:grid-cols-3">
            <CurrentProjectGrid currentProjects={currentProjects} />
          </div>
        </div>
      )}

      <div className="mt-4 self-end">
        <Pagination
          currentPage={Number(searchParams.get("page") ?? 1)}
          totalItems={total}
          itemsPerPage={perPage}
          onPageChange={handleChangePage}
        />
      </div>
    </div>
  );
}

export default HomePage;
