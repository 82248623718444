export const ZoomControl = (map: google.maps.Map): HTMLDivElement => {
  const controlDiv = document.createElement('div');

  controlDiv.style.zIndex = '1';
  controlDiv.style.padding = '16px';

  const controlWrapper = document.createElement('div');
  controlWrapper.style.width = '36px';
  controlWrapper.style.height = '70px';
  controlWrapper.style.cursor = 'pointer';
  controlWrapper.style.borderRadius = '0px';
  controlWrapper.style.overflow = 'hidden';
  controlDiv.appendChild(controlWrapper);

  const createButton = (
    width: string,
    height: string,
    backgroundColor: string,
    hoverColor: string,
    type: 'in' | 'out',
  ) => {
    const button = document.createElement('div');
    button.className = `zoom-control-button ${type}`;
    button.style.width = width;
    button.style.height = height;
    button.style.backgroundColor = backgroundColor;

    button.addEventListener('mouseover', () => {
      button.style.backgroundColor = hoverColor;
    });
    button.addEventListener('mouseout', () => {
      button.style.backgroundColor = backgroundColor;
    });

    return button;
  };

  const zoomInButton = createButton('32px', '32px', 'white', 'lightgrey', 'in');
  controlWrapper.appendChild(zoomInButton);

  const zoomOutButton = createButton('32px', '33px', 'white', 'lightgrey', 'out');
  zoomOutButton.style.borderTop = '1px solid rgba(0, 0, 0, 0.1)';
  controlWrapper.appendChild(zoomOutButton);

  zoomInButton.addEventListener('click', () => {
    if (map) {
      const currentZoom = map.getZoom();
      if (currentZoom !== undefined) {
        map.setZoom(currentZoom + 1);
      }
    }
  });

  zoomOutButton.addEventListener('click', () => {
    if (map) {
      const currentZoom = map.getZoom();
      if (currentZoom !== undefined) {
        map.setZoom(currentZoom - 1);
      }
    }
  });

  return controlDiv;
};
