import React from "react";
import { useNavigate } from "react-router-dom";

const Customers = () => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/add-new-customers");
  };

  return (
    <div className="px-4">
      <h1>Main page of Customers Page</h1>

      {/* sample button to navigate to add customer page */}
      <button
        type="submit"
        className="my-4 rounded-md bg-primaryPurple-200 px-4 py-2 text-white outline-none"
        onClick={handleNavigate}
      >
        Add New Customer
      </button>
    </div>
  );
};

export default Customers;
