import React, { useState, useRef } from "react";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { ClientSeller, UserRoles } from "../../../types";
import { api } from "../../../api";
import { NotificationModal } from "../../../components/NotificationModal";

export const InitialClient: ClientSeller = {
  username: null,
  role: UserRoles.CLIENT_SELLER,
  client_name: null,
  contact_person_name: null,
  contact_number: null,
  designation: null,
  email: null,
  registered_office_address: null,
};

const AddNewCustomer = () => {
  const navigate = useNavigate();
  const [clientSeller, setClientSeller] = useState<ClientSeller>(InitialClient);
  const [errors, setErrors] = useState<any>({
    client_name: false,
  });
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const clientSellerRef = useRef({ clientName: "" });

  // function to handle modal open state
  const handleOpenModal = () => setIsOpenModal((prev) => !prev);

  const handleNavigate = () => {
    navigate("/customers");
  };

  // function to handle user inputs
  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = event.target;
    // Update the state only if the email is valid or for other fields
    setClientSeller((prev: any) => ({ ...prev, [name]: value }));
  };

  // function to validate the email
  // const validateEmailAddress = (email: string) => {
  //   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  //   return emailRegex.test(email);
  // };

  // function to validate empty fields
  const validateEmptyFields = () => {
    const newErrors = {
      client_name: clientSeller?.client_name === null,
    };
    setErrors(newErrors);
    return !Object.values(newErrors).some((error) => error);
  };

  const handleSubmit = async () => {
    try {
      if (!validateEmptyFields()) {
        return;
      }

      const finalClientSeller = {
        username: clientSeller?.username,
        role: clientSeller?.role,
        client_name: clientSeller?.client_name,
        contact_person_name: clientSeller?.contact_person_name,
        contact_number: clientSeller?.contact_number,
        designation: clientSeller?.designation,
        email: clientSeller?.email,
        registered_office_address: clientSeller?.registered_office_address,
      };

      const response = await api.post(`/proclime-admin/users`, finalClientSeller);
      if (response) {
        // assign client ref
        clientSellerRef.current.clientName = response?.data?.client_name;
        // display success notification modal
        setIsOpenModal(true);
        // clear the state
        setClientSeller(InitialClient);
      }
    } catch (err) {
      console.error("An error occurred", err);
    }
  };

  return (
    <div className="space-y-4 px-4">
      {/* breadcrumb goes here */}
      <div>
        <span className="font-medium text-black">
          Customers / <span className="text-gray-700">Add New Customers</span>
        </span>
      </div>

      {/* go back */}
      <div className="flex items-center gap-2">
        <IoMdArrowRoundBack
          className="cursor-pointer text-primaryPurple-100"
          onClick={handleNavigate}
        />
        <h1 className="text-lg font-semibold">Add New Customers</h1>
      </div>

      <h1 className="font-medium text-black">Personal Information</h1>

      {/* form goes here */}
      <div className="grid grid-cols-3 gap-4">
        {/* user Input */}
        <div className="flex flex-col gap-1">
          <label className="text-sm font-medium text-black">
            Client Seller Name <span className="font-medium text-red-800">*</span>
          </label>
          <input
            type="text"
            name="client_name"
            id="client_name"
            className={`rounded-md border p-2 outline-none ${
              errors?.client_name ? "border-red-600" : "border-gray-400"
            }`}
            onChange={handleOnChange}
            value={clientSeller?.client_name ? clientSeller.client_name : ""}
          />
          {errors?.client_name ? (
            <span className="text-sm text-red-700">Client name is Required</span>
          ) : (
            ""
          )}
        </div>

        <div className="flex flex-col gap-2">
          <label className="text-sm font-medium text-black">Role</label>
          <input
            type="text"
            name="role"
            id="role"
            className="rounded-md border border-gray-400 p-2 outline-none"
            onChange={handleOnChange}
            value={clientSeller?.role ? clientSeller?.role : ""}
            disabled={true}
          />
        </div>

        <div className="flex flex-col gap-2">
          <label className="text-sm font-medium text-black">User Name</label>
          <input
            type="text"
            name="username"
            id="username"
            className="rounded-md border border-gray-400 p-2 outline-none"
            onChange={handleOnChange}
            value={clientSeller?.username ? clientSeller?.username : ""}
          />
        </div>

        <div className="flex flex-col gap-2">
          <label className="text-sm font-medium text-black">Contact Person Name</label>
          <input
            type="text"
            name="contact_person_name"
            id="contact_person_name"
            className="rounded-md border border-gray-400 p-2 outline-none"
            onChange={handleOnChange}
            value={clientSeller?.contact_person_name ? clientSeller?.contact_person_name : ""}
          />
        </div>

        <div className="flex flex-col gap-2">
          <label className="text-sm font-medium text-black">Contact Number</label>
          <input
            type="text"
            name="contact_number"
            id="contact_number"
            className="rounded-md border border-gray-400 p-2 outline-none"
            onChange={handleOnChange}
            value={clientSeller?.contact_number ? clientSeller?.contact_number : ""}
          />
        </div>

        <div className="flex flex-col gap-2">
          <label className="text-sm font-medium text-black">Designation</label>
          <input
            type="text"
            name="designation"
            id="designation"
            className="rounded-md border border-gray-400 p-2 outline-none"
            onChange={handleOnChange}
            value={clientSeller?.designation ? clientSeller?.designation : ""}
          />
        </div>

        <div className="flex flex-col gap-2">
          <label className="text-sm font-medium text-black">Email</label>
          <input
            type="text"
            name="email"
            id="email"
            className="rounded-md border border-gray-400 p-2 outline-none"
            onChange={handleOnChange}
            value={clientSeller?.email ? clientSeller?.email : ""}
          />
        </div>

        <div className="flex flex-col gap-2">
          <label className="text-sm font-medium text-black">Registered Office Address</label>
          <input
            type="text"
            name="registered_office_address"
            id="registered_office_address"
            className="rounded-md border border-gray-400 p-2 outline-none"
            onChange={handleOnChange}
            value={
              clientSeller?.registered_office_address ? clientSeller?.registered_office_address : ""
            }
          />
        </div>
      </div>

      {/* submit button */}
      <div>
        <button
          type="submit"
          className="rounded-md bg-primaryPurple-100 px-4 py-2 font-normal text-white duration-300 hover:bg-primaryPurple-200"
          onClick={handleSubmit}
        >
          Add New Client Seller
        </button>
      </div>
      <NotificationModal
        isOpen={isOpenModal}
        handleOpen={handleOpenModal}
        title={"New Client Seller created Successfully."}
        content={`A New client seller ${clientSellerRef?.current?.clientName} created successfully!`}
      />
    </div>
  );
};

export default AddNewCustomer;
