import { EProjectTypes, EViewMode, TReProject, TReProjectData } from '../../types';
import { createEntity, LoadableEntityState } from '../lib/';

export type ProjectRecord<V = string> = Record<string, V>;

export type FilterValues = {
  device_codes: Array<string>;
  device_types: Array<string>;
  technologies: Array<string>;
  technology_groups: Array<string>;
  countries: Array<{ id: number; country: string }>;
  max_capacity: number;
  min_capacity: number;
  capacity_bins: Array<string>;
  registrant: Array<string>;
  min_certificates_issued: string;
  max_certificates_issued: string;
  states: Array<{ id: number; name: string }>;
  sellers: Array<{ id: number; username: string }>;
  seller_deals: Array<string>;
};

export type Filter = {
  device_code: Array<string>;
  device_type: Array<string>;
  technology: Array<string>;
  technology_group: Array<string>;
  country: string;
  max_capacity: number;
  min_capacity: number;
  capacity_bins: Array<string>;
  commissioning_date_from: Date;
  commissioning_date_to: Date;
  last_updated_start_date: string;
  last_updated_end_date: string;
  registration_date_from: Date;
  registration_date_to: Date;
  expiry_date_from: Date;
  expiry_date_to: Date;
  registrant: Array<string>;
  min_certificates_issued: string;
  max_certificates_issued: string;
  state: string;
  user_id: number; // seller
  seller_deal: Array<string>;
};

export interface ProjectsState {
  projects: LoadableEntityState<TReProject>;

  allProjects: LoadableEntityState<TReProject>;

  indicators: { total: number; inProgress: number };

  selected: EProjectTypes;
  // TODO: move it to filters
  search: { level: number; id: string; name: string } | null;

  searchByNaming: string;

  filterValues: LoadableEntityState<FilterValues>;

  filters: Partial<Filter>;

  pagination: { total: number; page: number; perPage: number };

  uploadedFiles: Array<{ uploadedFileType: number; uploadedFile: File }>;

  viewMode: EViewMode;

  selectedProject: TReProjectData | null;
}

export const projectsInitialState: ProjectsState = {
  projects: createEntity({
    items: [],
  }),

  allProjects: createEntity({
    items: [],
  }),

  indicators: { total: 0, inProgress: 0 },

  search: null,
  searchByNaming: '',
  selected: EProjectTypes.REGISTERED,

  filterValues: createEntity({
    device_codes: [],
    device_types: [],
    technologies: [],
    technology_groups: [],
    countries: [],
    max_capacity: 0,
    min_capacity: 0,
    capacity_bins: [],
    commissioning_start_date: '',
    commissioning_end_date: '',
    last_updated_start_date: '',
    last_updated_end_date: '',
    registration_start_date: '',
    registration_end_date: '',
    expiration_start_date: '',
    expiration_end_date: '',
    registrant: [],
    min_certificates_issued: '',
    max_certificates_issued: '',
    states: [],
    sellers: [],
    seller_deals: [],
  }),

  filters: {},

  pagination: { total: 0, page: 1, perPage: 10 },

  uploadedFiles: [],

  viewMode: EViewMode.LIST,
  selectedProject: null,
};
