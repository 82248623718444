import { createAction } from '@reduxjs/toolkit';
import { createActions } from '../lib';
import { EProjectTypes, EViewMode, ProjectsInterface } from '../../types';
import { FilterValues } from './state';

export const loadProjectsSpecific = createActions<void, Partial<ProjectsInterface>, string>(
  'projects/LOAD_PROJECTS_SPECIFIC',
);

export const loadIndicatorsAction = createAction<{ total: number; inProgress: number }>(
  'projects/LOAD_INDICATORS',
);

export const loadAllProjectsAction = createAction<Pick<ProjectsInterface, 'projects'>>(
  'projects/LOAD_ALL_PROJECTS',
);

export const changeSelectedTypeAction = createAction<EProjectTypes>('projects/CHANGE_PROJECT_TYPE');

export const changeSearchAction = createAction<string>('projects/CHANGE_SEARCH_ACTION');

export const changeFilterAction = createAction<any>('projects/CHANGE_FILTER_ACTION');

export const changeViewModeAction = createAction<EViewMode>('projects/CHANGE_VIEW_MODE');

export const changePaginationAction = createAction<{
  page: number;
  perPage?: number;
}>('projects/CHANGE_PAGINATION_ACTION');

export const loadFiltersValues = createActions<void, Partial<FilterValues>, string>(
  'projects/LOAD_FILTERS_VALUES',
);

export const resetFilterAction = createAction('projects/RESET_FILTER');

export const changeUploadFiles = createAction<any>('projects/CHANGE_UPLOAD_FILE_ACTION');
export const deleteUploadFiles = createAction<string>('projects/DELETE_UPLOAD_FILE_ACTION');
export const clearUploadFiles = createAction('projects/CLEAR_UPLOAD_FILES_ACTION');

// create an action to set clicked project
export const setSelectedReProject = createAction<any>('projects/SET_SELECTED_PROJECT');
