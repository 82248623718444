import React, { useEffect, useState } from "react";
import { api } from "../../api";
// import NoOfCertificatesChart from "./components/NoOfCertificatesChart";
import Select from "react-select";
import { BiUpArrowAlt } from "react-icons/bi";
import { RSSFeedItems } from "./RSSFeed";
import {
  BarChart,
  Bar,
  XAxis,
  ResponsiveContainer,
  Tooltip,
  YAxis,
  Label,
  CartesianGrid,
} from "recharts";
import {
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  SliderMark,
  Box,
} from "@chakra-ui/react";
import { TableProjects } from "../Home/components/TableProjects";

function Dashboard() {
  const DEFAULT_PRICE_PER_MWH = 0.7;
  const [dashBoardData, setDashBoardData] = useState<any>({});
  const [clientList, setClientList] = useState<any>([]);
  const [selectedClient, setSelectedClient] = useState<any>(null);
  const [selectedClientInfo, setSelectedClientInfo] = useState<any>(null);
  const [documentTypes, setDocumentTypes] = useState<any>([]);
  const [documentUploadStatus, setDocumentUploadStatus] = useState<any>({
    submitted: 0,
    pending: 0,
  });
  const [data, setData] = useState<any>([]);
  const [pricePerMWh, setPricePerMWh] = useState<number>(0);
  const [totalGen, setTotalGen] = useState<number>(0);
  const [selectedClientProjects, setSelectedClientProjects] = useState<any>([]);

  // get client project information
  useEffect(() => {
    api
      .post(`/admin/re-projects/search`, {
        user_id: selectedClient?.id,
      })
      .then((response) => {
        setSelectedClientProjects(response?.data?.items);
      });
  }, [selectedClient]);

  useEffect(() => {
    if (selectedClient) {
      getClientProjectInformation(selectedClient?.id);
      calculateGeneration(selectedClient.id);
    }
  }, [selectedClient, pricePerMWh]);

  // get clients list
  useEffect(() => {
    api
      .post("/user/search", {
        role: "CLIENT_SELLER",
        page: 1,
        take: 300,
      })
      .then((response: any) => {
        // Filter out clients where client_name is empty or null, then map the rest
        const mappedClients = response?.data?.items
          ?.filter((client: any) => client?.client_name) // This filters out clients with empty or null names
          .map((client: any) => ({
            name: "client",
            value: client?.client_name,
            label: client?.client_name,
            id: client.id,
          }));

        setClientList(mappedClients);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // call /admin/dashboard/ endpoint
  useEffect(() => {
    getAdminDashboardData()
      .then((response: any) => {
        if (response) {
          setDashBoardData(response);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    fetchDocumentTypes();
  }, []);

  // function to get total capacity
  const getTotalCapacity = () => {
    try {
      if (dashBoardData !== null) {
        let totalCapacity = 0;
        for (const object of Object.values(dashBoardData?.client_data)) {
          const clientData = object as any;
          totalCapacity += clientData?.total_capacity;
        }
        return totalCapacity;
      }
    } catch (err) {
      console.log(err);
    }
  };

  // get all available document types
  const fetchDocumentTypes = async () => {
    try {
      const fetchedDocumentTypes = await api.get("/document/types");
      setDocumentTypes(fetchedDocumentTypes.data);
    } catch (error) {
      console.log("error =>", error);
    }
  };

  // function to get dashboard response
  const getAdminDashboardData = async () => {
    const response = await api.get("/admin/dashboard/");
    return response.data;
  };

  // handle change client
  const handleChangeClient = (e: any) => {
    setPricePerMWh(DEFAULT_PRICE_PER_MWH);
    const selectedClient = dashBoardData.client_data[e.value];
    setSelectedClientInfo(selectedClient);
    setSelectedClient(e);
  };

  // fetch selected client's project information
  const getClientProjectInformation = async (clientID: number) => {
    try {
      let submittedDocumentsCount = 0;
      let pendingDocumentsCount = 0;
      const response = await api.post(`/admin/re-projects/search`, {
        user_id: clientID,
      });
      const projects = response?.data?.items;

      // iterate through projects and get count of uploaded documents
      if (projects?.length > 0) {
        for (const project of projects) {
          const uploadedDocumentsResponse = await api.get(`/document/uploaded-docs/${project?.id}`);

          // if project has uploaded documents
          if (uploadedDocumentsResponse?.data) {
            const objectList = Object.keys(uploadedDocumentsResponse.data);

            // Calculate submitted document count
            if (objectList.length > 0) {
              objectList.forEach((object: any) => {
                const length = uploadedDocumentsResponse.data[object].length;
                submittedDocumentsCount += length;
              });
            }

            // Calculate pending document count as (10 - objectList.length)
            pendingDocumentsCount += documentTypes.length - objectList.length;
          }
        }
      }

      // Set the document counts in the state
      setDocumentUploadStatus({
        submitted: submittedDocumentsCount,
        pending: pendingDocumentsCount,
      });
    } catch (err) {
      console.error("An error occurred while fetching projects", err);
    }
  };

  // calculate the potential generation
  const calculateGeneration = async (clientID: number) => {
    let totalGen = 0;
    const solar_value: number = 0.325;
    const wind_value: number = 0.224;
    /*
    total calculation => age factor = 0.9
                      => pv ground mounted || pv aggregated = solar
                         Onshore = wind
                      => capacity
                      => location [doesn't consider]
     total calculation = 0.9 * solar value || wind value * capacity * 24 * 300
     */
    const response = await api.post(`/admin/re-projects/search`, {
      user_id: clientID,
    });
    const projects = response?.data?.items;

    // loop through the projects
    projects?.forEach((project: any) => {
      switch (project?.technology) {
        case "PV Aggregated": {
          totalGen = 0.9 * solar_value * project.capacity * 24 * 300 * pricePerMWh;
          break;
        }
        case "PV Ground mounted": {
          totalGen = 0.9 * solar_value * project.capacity * 24 * 300 * pricePerMWh;
          break;
        }
        case "PV Roof Mounted (single installation)": {
          totalGen = 0.9 * solar_value * project.capacity * 24 * 300 * pricePerMWh;
          break;
        }
        case "PV Floating": {
          totalGen = 0.9 * solar_value * project.capacity * 24 * 300 * pricePerMWh;
          break;
        }
        case "Group Solar Rooftop": {
          totalGen = 0.9 * solar_value * project.capacity * 24 * 300 * pricePerMWh;
          break;
        }
        case "Onshore": {
          totalGen = 0.9 * wind_value * project.capacity * 24 * 300 * pricePerMWh;
          break;
        }
        case "Offshore": {
          totalGen = 0.9 * wind_value * project.capacity * 24 * 300 * pricePerMWh;
          break;
        }
        default: {
          break;
        }
      }
    });

    totalGen += totalGen;
    setTotalGen(totalGen);

    const data = [];

    // generate 5 year data set
    for (let i = 0; i < 5; i++) {
      const startingYear = 2024;
      data.push({ year: startingYear + i, revenue: totalGen });
    }
    setData(data);
  };

  const labelStyles = {
    mt: "2",
    ml: "-2.5",
    fontSize: "sm",
  };

  return (
    <div className="px-4">
      {/* date component */}
      {/*<div>*/}
      {/*  <h1>date component goes here</h1>*/}
      {/*</div>*/}

      {/*  dashboard part goes here*/}
      <div className="my-4 grid grid-cols-4 grid-rows-3 gap-4">
        {/*  no of certificates sold box*/}
        <div className="row-span-2 flex flex-col rounded-md p-4 shadow-md">
          <h1 className="font-medium">Earning Potential over 5 years</h1>
          {/*<NoOfCertificatesChart />*/}

          {data.length > 0 && (
            <ResponsiveContainer width="100%" height="100%" className="mt-4">
              <BarChart data={data} margin={{ left: 20, bottom: 5 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="year">
                  <Label value="Year" offset={-5} position="insideBottom" />
                </XAxis>
                <YAxis>
                  <Label
                    value="Earning Potential($)"
                    offset={10}
                    position="left"
                    angle={-90}
                    dy={-60}
                  />
                </YAxis>
                {/*<Legend />*/}
                <Tooltip offset={-10} />
                <Bar dataKey="revenue" fill="#8884d8" />
              </BarChart>
            </ResponsiveContainer>
          )}
        </div>

        {/*  client select dropdown */}
        <div className="rounded-md p-4 shadow-md">
          <h1 className="font-medium">Client</h1>
          <Select
            options={clientList}
            name="client"
            id="client"
            onChange={handleChangeClient}
            className="mt-4"
            styles={{
              control: (baseStyles) => ({
                ...baseStyles,
                border: "1px solid #A6A6A6",
                height: "42px",
              }),
            }}
          />
        </div>

        {/*  To-do box */}
        <div className="row-span-2 rounded-md p-4 shadow-md">
          <h1 className="font-medium">To Do</h1>
          <div className="mt-4 grid grid-cols-1 grid-rows-2 gap-4">
            <div>
              <h1 className="text-5xl font-medium text-black">{documentUploadStatus.submitted}</h1>
              <p className="text-sm text-gray-600">Submitted Documents</p>
            </div>
            <div>
              <h1 className="text-5xl font-medium text-black">{documentUploadStatus.pending}</h1>
              <p className="text-sm text-gray-600">Pending Documents</p>
            </div>
            {/*<div>*/}
            {/*  <h1 className="text-xl font-medium text-black">40</h1>*/}
            {/*  <p className="text-sm text-gray-600">Submit Document</p>*/}
            {/*</div>*/}
            {/*<div>*/}
            {/*  <h1 className="text-xl font-medium text-black">50</h1>*/}
            {/*  <p className="text-sm text-gray-600">Sample topic</p>*/}
            {/*</div>*/}
          </div>
        </div>

        {/*  registry updates box */}
        <div className="row-span-2 rounded-md p-4 shadow-md">
          <h1 className="font-medium">News Updates</h1>
          {/*  registry updates list displays here*/}
          <div className="mt-4 h-80 overflow-auto">
            {RSSFeedItems?.map((registry: any, index: number) => (
              <div key={index} className="border-b border-b-gray-300 p-2">
                <div className="flex items-start gap-2">
                  {/*<div className="rounded-sm bg-gray-300 p-2">image goes here</div>*/}
                  <div>
                    <a className="font-normal" href={registry.URL}>
                      {registry.News}
                    </a>
                    <h1 className="text-gray-600">{registry.DateOfPublishing}</h1>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/*  No of projects box */}
        <div className="rounded-md p-4 shadow-md">
          <h1 className="font-medium">No of Projects</h1>
          <div className="mt-4 flex items-center justify-between">
            <h1 className="text-2xl font-semibold">
              {selectedClient != null
                ? selectedClientInfo != null
                  ? selectedClientInfo.num_projects
                  : 0
                : dashBoardData?.total_projects}
            </h1>
            <div className="flex items-center gap-2">
              <h1>+ {dashBoardData?.percent_change_24h}%</h1>
              <BiUpArrowAlt size={25} />
            </div>
          </div>
        </div>

        {/*  Total revenue generated box */}
        <div className="rounded-md p-4 shadow-md">
          <h1 className="font-medium">Price per MWh</h1>
          <div className="mt-4">
            {/* revenue */}
            <h1 className="mt-3 font-normal">1$ = 82₹ </h1>
            {/* slider goes here */}
            <Box p={4}>
              <Slider
                defaultValue={selectedClient != null ? pricePerMWh : 0.2}
                min={0.2}
                max={0.7}
                step={0.1}
                onChange={(val) => setPricePerMWh(val)}
                isDisabled={selectedClient == null}
                value={pricePerMWh}
              >
                <SliderMark value={0.2} {...labelStyles}>
                  0.2$
                </SliderMark>
                <SliderMark value={0.3} {...labelStyles}>
                  0.3$
                </SliderMark>
                <SliderMark value={0.4} {...labelStyles}>
                  0.4$
                </SliderMark>
                <SliderMark value={0.5} {...labelStyles}>
                  0.5$
                </SliderMark>
                <SliderMark value={0.6} {...labelStyles}>
                  0.6$
                </SliderMark>
                <SliderMark value={0.7} {...labelStyles}>
                  0.7$
                </SliderMark>
                <SliderTrack bg="blue.100">
                  <SliderFilledTrack bg="blue.500" />
                </SliderTrack>
                <SliderThumb boxSize={6} />
              </Slider>
            </Box>
          </div>
          <h1 className="font-medium">{`${totalGen.toFixed(2)}$ = ${(totalGen * 82).toFixed(
            2,
          )}₹`}</h1>
        </div>

        {/*  Total capacity box */}
        <div className="rounded-md p-4 shadow-md">
          <h1 className="font-medium">Total Capacity</h1>
          <h1 className="mt-4 text-2xl font-semibold">
            {selectedClientInfo != null ? selectedClientInfo.total_capacity : getTotalCapacity()}
          </h1>
        </div>
      </div>
      {/* projects table */}
      {selectedClient && (
        <div className="py-4">
          <h1 className="mb-4 text-lg font-medium text-black">Selected Client's Projects</h1>
          <TableProjects reProjects={selectedClientProjects} isLoading={false} />
        </div>
      )}
    </div>
  );
}

export default Dashboard;
