import { Provider } from "react-redux";
import { Route, Routes } from "react-router-dom";

import HomePage from "../pages/Home/HomePage";
// import AuthPage from "../pages/Auth/AuthPage";
// auth page v2
import AuthPageV2 from "../pages/Auth/AuthPageV2";
import { PersistGate } from "redux-persist/integration/react";
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";
import OAuthSuccess from "../pages/Auth/Microsoft/OAuthSuccess";
import OAuthError from "../pages/Auth/Microsoft/OAuthError";
import SideBar from "./SideBar/SideBar";
import TopBar from "./TopBar/TopBar";
import { persistor, store } from "../store";
import { LoadScriptOnce } from "./Map/LoadScript";
import { ChakraProvider } from "@chakra-ui/react";
import AddNewProject from "../pages/Projects/AddNewProject/AddNewProject";
import Dashboard from "../pages/Dashboard/Dashboard";
import { useSelectSidebarExpandSelector } from "../store/sidebar/selectors";
import AddNewCustomer from "../pages/Customers/AddNewCustomer/AddNewCustomer";
import Customers from "../pages/Customers/Customers";

// testing route
import ProjectDescriptionPage from "../pages/Projects/ProjectDescriptionPage";
// import ProjectsPage from "../pages/Projects/ProjectsPage";

const App = () => {
  const isSideBarOpen = useSelectSidebarExpandSelector();

  return (
    <ChakraProvider>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <LoadScriptOnce>
            <div className="flex font-primary">
              <div>
                {/* sidebar */}
                <PrivateRoute redirectTo="/login">
                  <SideBar />
                </PrivateRoute>
              </div>
              <div
                className="w-full bg-white"
                style={{
                  maxWidth: `calc(100vw - ${isSideBarOpen ? 72 * 4 : 20 * 4}px)`,
                }}
              >
                <PrivateRoute redirectTo="/login">
                  {/*<TopBar setIsSideBarOpen={setIsSideBarOpen} />*/}
                  <TopBar />
                </PrivateRoute>
                <Routes>
                  <Route
                    path="/"
                    index={true}
                    element={
                      <PrivateRoute redirectTo="/login">
                        <HomePage />
                      </PrivateRoute>
                    }
                  />

                  {/* <Route
                    path="recommendation/:id"
                    element={
                      // <PrivateRoute redirectTo="/login">
                        <RecommendationPage />
                      // </PrivateRoute>
                    }
                  /> */}

                  <Route
                    path="project/:id"
                    element={
                      // <PrivateRoute redirectTo="/login">
                      <ProjectDescriptionPage />
                      // </PrivateRoute>
                    }
                  />

                  <Route
                    path="add-new-project"
                    element={
                      // <PrivateRoute redirectTo="/login">
                      <AddNewProject />
                      // </PrivateRoute>
                    }
                  />
                  <Route
                    path="customers"
                    element={
                      // <PrivateRoute redirectTo="/login">
                      <Customers />
                      // </PrivateRoute>
                    }
                  />

                  <Route
                    path="add-new-customers"
                    element={
                      // <PrivateRoute redirectTo="/login">
                      <AddNewCustomer />
                      // </PrivateRoute>
                    }
                  />

                  <Route
                    path="dashboard"
                    element={
                      // <PrivateRoute redirectTo="/login">
                      <Dashboard />
                      // </PrivateRoute>
                    }
                  />

                  {/* <Route
                    path="/projects"
                    index={true}
                    element={
                      // <PrivateRoute redirectTo="/login">
                      <ProjectsPage />
                      // </PrivateRoute>
                    }
                  /> */}

                  {/* testing route for project description page */}
                  <Route
                    path="/projectsDesc"
                    index={true}
                    element={
                      // <PrivateRoute redirectTo="/login">
                      <ProjectDescriptionPage />
                      // </PrivateRoute>
                    }
                  />

                  <Route
                    path="/login"
                    element={
                      <PublicRoute restricted redirectTo="/">
                        <AuthPageV2 />
                      </PublicRoute>
                    }
                  />
                  <Route
                    path="/oauth/success"
                    element={
                      <PublicRoute restricted redirectTo="/">
                        <OAuthSuccess />
                      </PublicRoute>
                    }
                  />

                  <Route
                    path="/oauth/error"
                    element={
                      <PublicRoute restricted redirectTo="/">
                        <OAuthError />
                      </PublicRoute>
                    }
                  />
                </Routes>
              </div>
            </div>
          </LoadScriptOnce>
        </PersistGate>
      </Provider>
    </ChakraProvider>
  );
};

export default App;
